.loginBackg{
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
    background-color: #E9E9E9;
}
.formContainer{
    max-width: 450px !important;
    margin: auto;
    padding:  2em;
    display: flex;
    align-items: center;
    height: 100vh;
}
.loginLogo{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 30vh;
    width: 30vh;
    margin: auto;
    margin-bottom: 1vh;
}
#welcome{
    font-weight: 600;
    font-size: 30px;
    line-height: 38px; 
    color: #101828;
}
#loginTxt{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px; 
    color: #667085;
    
    
}
.loginForm label{
text-align: start;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #344054;
margin-top: 1.5em;
margin-bottom: 0.5em;
display: block;
}
.loginForm input{
    font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #667085;
background: #FFFFFF;
/* Gray/300 */
width: 100%;
padding:8px 10px;

border: 1px solid #D0D5DD;
/* Shadow/xs */

border-radius: 8px;
}
.loginForm input[type="password"]{
    padding:9px 10px;

}
.forgetDiv{
    margin-top: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.forgetDiv label{
  
font-weight: 500;
font-size: 14px;
display: inline-block;
color: #344054;
margin: 0 0.5em;
}
.forgetDiv input{
   width: auto;
}
.forgetDiv p{

font-weight: 700;
font-size: 14px;
margin-bottom: 0;
color: #ED9434;

}
.forgetDiv input{
    position: relative;
    top: 2px;
    cursor: pointer;
}


#loginBtn{
    font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  background: #292119;
  border: 1px solid #292119;
  padding: 9px 10px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  margin: 2em 0;
  }
  #reg{
    font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  
  color: #475467;
  }
  #reg span{
    font-weight: 600;
    color: #ED9434;
    
  }
 
  #languageBtn{
    text-align: end;

  }
  .loginEn #languageBtn svg{
    margin-left: 0;
    margin-right: 5px;

  }
#languageBtn button{
    background: #ED9434;
/* Primary */

padding:  10px;
font-weight: 600;
font-size: 20px;
line-height: 20px;
color: #FFFFFF;
text-transform: uppercase;
border: 1px solid #ED9434;
border-radius: 8px;
}
#languageBtn svg{
    position: relative;
    bottom: 2px;
    margin-left: 5px;
}
.loaderDiv{
    justify-content: center; 
    align-items: center;
    display: flex;}
.loginForm span{
    font-size: 14px;
    color: brown;
    text-align: start;
    display: block;
}
.invalid{
    border: 2px solid brown !important;
}
@media screen and (max-width: 575px){
    #languageBtn button{
        font-size: 16px;
padding: 7px;

    }
    .loginBackg{
        display: none;
    }
    .loginLogo{
        height: 42vw;
        width: 28vw;
    }

    #welcome{
        font-size: 24px;
        margin-bottom: 7px;

    }
    #loginTxt{
        font-size: 16px;

    }
    .loginForm input{

    }
}