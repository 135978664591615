#navbar{
padding:0 10px;

border-bottom: 1px solid #EAECF0;
display: flex;
justify-content: space-between;
align-items: center;
}
.flexRow{
    display: flex;
    align-items: center;
}
#navLogo{
    width: 49px;
    height: 73px;
display: inline-block;
background-size: contain;
background-repeat: no-repeat;
}
#usrImg{
    width: 40px;
    height: 40px; 
  
    border-radius: 50%;
    background-size: cover;
background-repeat: no-repeat;
}
#navbar a{
    text-decoration: none;
    font-weight: 600;
font-size: 16px;
line-height: 24px;
color: #344054;

padding: 8px 12px;

}
.home{
    margin: 1em;
}
#navbar a:hover{
    color: #FDB022;
background: #F9F5FF;
}
#navbar .active{
    color: #FDB022;
background: #F9F5FF;

}
#navbar button{
    background: transparent;
    border: none;
}
#navbar svg{
    color:'#667085'
}
#settings{
    margin: 0.5em;
}
#search{
    margin-left: 0.5em;
}
#globe{
    font-weight: 600;
font-size: 18px;
line-height: 24px;
color: #667085;
}
#srchDiv{
    text-align: start;
    margin-top: 48px;
}
#hello{
 
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #344054;
    
}
#look{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    
}
#srchInput{
    position: relative;
    display: inline-block;
}
#srchInput input{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
   width: 25vw;
    color: #667085;
    padding:9px 10px;
padding-right: 2.1em;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    min-width: 300px;

}
.dashboardEn #srchInput input{
    padding-right:0;

    padding-left: 2.1em;

}
.dashboardEn #statusBtn{
    margin-left: 0 !important;
    margin-right: 2em;
}
.dashboardEn #orderDet svg{
    margin-right: 0;
    margin-left: 5px;
}
.dashboardEn #startBtn{
    margin-left: 0;
    margin-right: 2em;
}
.dashboardEn #srchInput svg{
    right: auto;
    left: 12px;

}

#srchInput input::placeholder{
    font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #667085;

}
#srchInput svg{
position: absolute;
top: 10px;
z-index: 1;
right: 12px;
}
#srchBtn{
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    background: #ED9434;
border: none;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
padding: 12px 2em;
margin-right: 3em;
}
.dashboardEn #srchBtn{
    margin-right: 0 !important;
margin-left: 3em;}

#srchresult{
margin-top: 1em;
border: 1px solid #EAECF0;
padding: 20px;
border-radius: 12px;
}
#resultTitle{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
   text-align: start;
    padding-bottom: 20px;
    color: #344054;
    border-bottom: 1px solid #EAECF0;

}
#resultDiv{
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 20px;
display: flex;
justify-content: space-between;
margin-top: 1em;
}
#spaceBtn{
    justify-content: space-between;
    display: flex;
 
}
#address{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    
    margin-top: 0.5em;
    margin-bottom: 0;
    text-align: start;
}
#address span{
font-weight: 600;
}
#orderDiv{
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 16px;

margin-top: 1em;   
}
#resnum{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #1D2939;
    text-align: start;
}
#orderPrice{
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
 text-align: start;
    color: #ED9434;
}
#orderPrice span{
    font-weight: 600;

}
#orderPhone{
    text-align: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
margin-bottom:5px;
    color: #475467; 
}
#orderPhone span{
    font-weight: 600;}
#actionDiv{
display: flex;
flex-direction: column;
justify-content: space-between;
}
#resname{
    text-align: start;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
margin-bottom:5px;
    color: #475467;
}
#resphone{
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    text-align: start;
    
    /* Primary */
    
    color: #ED9434;
}
#startBtn{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    background: #ED9434;
border: 1px solid #ED9434;
padding: 7px 15px;
color: #FFFFFF;
    margin-left: 2em;
}
#orderDet{
    background: #FFFFFF;
 border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 7px 15px;
    font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #344054;

}
.mobileDate{
    display: none;
}
#orderDate{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
text-align: end;
margin-bottom: 0;
    color: #667085;
}
#orderDet svg{
margin-right: 5px;
color: #344054;

}
#navbarMobile{
    display: none;
}

.mobileLinks{
    height: 100vh;
    top: 0;
    background-color: white;
    width: 300px;
    position: absolute;
 transition: 1s;
 transform: translateX(0);
left: 0;
text-align: end;
padding: 15px;
padding-top: 10px;
z-index: 11;
position: fixed;
}
.mobileLinksClose{
    transition: 1s;

transform: translateX(-300px);
}

.closeNav{
    pointer-events:all;

    transition: 2s;
    opacity: 1 ;
position:fixed;
z-index: 12;
top: 1.5em;
display:inline-block;

right: 0.5em;
}
.closeNav path{
    stroke: white;
}
.closeNavHidden{
    pointer-events: none;
        transition: 0s;
        opacity: 0 ;
    }

.sideNav{
    background: rgba(52, 64, 84, 0.2);
    backdrop-filter: blur(4.5px);    height: 100vh;
    width: 100vh;
    top: 0;
    left: 0;
    transition: 1s;
opacity: 1;
    z-index: 10;
    position: fixed;
}

.navClosed{

    opacity: 0;
    z-index: -1;
    transition: 1s;
    
    }
.navSearch{
  width: 100%;
}
.navSearch input{
    width: 100% !important;  
   
    padding: 8px 10px !important;
  padding-right: 2.2em !important;
}

#navList{
    text-align: start;
    list-style: none;
    padding: 0;
    margin-top: 1em;
    border-bottom: 1px solid #D0D5DD;
    
} 
#navList li{
margin-bottom: 15px;
}

#navList a{
    font-weight: 600;
font-size: 16px;
line-height: 24px;
width: 100%;
padding: 8px 10px; 

display: flex;
align-items: center;
color: #344054;
    text-decoration: none;
}
#navList li:hover{
    background: #F9FAFB;
    border-radius: 6px;
    
    
}
#navList li:hover a, #navList li:hover svg{
    color: #ED9434;
}
#navList svg{
  color: #667085;
    margin-left: 5px;
}
#navList .active{
    color: #ED9434 ;

    background: #F9FAFB ;
    border-radius: 6px;
}
#navList .active svg{
    color: #ED9434 ;

}
.navActive a{
color: #ED9434 !important;}
.navActive svg{
    color: #ED9434 !important;}
    #flexName p{
        font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #344054;
margin-right: 0.5em;
margin-bottom: 0;
    }
    .errMsg{
        color: brown;
    font-weight: 500;
        font-size: 14px;
        margin-bottom: 1em;
        display: block;
    }
    #logoutTitle{
        font-weight: 600;
        font-size: 26px;
        line-height: 20px;
        color: #344054;
     margin-bottom: 2em;
    }
    #navEmail{
        font-weight: 400 !important;

color: #475467 !important;
    }
    .userNav{
        display: flex;
        position: relative;
        justify-content: space-between;
    }
  .userNav  #usrImg{
      width: 59px;
  }
    .userNav button{
        position: absolute;
        left: 0;
    }
#navFlex{
    display: flex;
    text-align: start;
  
}
    .userNav p{
        display: inline-block;
        text-align: start;
    }

     /*Modal*/
  .modalOverlay{
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 13;
    background: rgba(49,49,49,0.8);

}
.imgModal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    border-radius: 20px;
  width: 90vw;
  max-width: 600px;
    z-index: 14;
padding: 40px;
padding-top: 60px;
   display: unset;
}
.imgModal img{
    margin-bottom: 1em;
    max-height: 30vh;
    max-width:  -webkit-fill-available;
}
.imgModal button{
    border-radius: 8px;
    border: none;
    color: white;
    font-weight: 600;
font-size: 16px;
line-height: 24px;
padding: 10px ;
}
#cameraInput{
    position: absolute;
    pointer-events: none;
    top: 0;
    opacity: 0;
}
#modalTitle{
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #000000;
    margin-bottom: 2em;
}
#modalText{
    font-weight: 500;
font-size: 20px;
line-height: 24px;
text-align: center;

color: #000000;
margin-bottom: 2em;
}
#uploadBtn{
    background: #ED9434;
    display: block;
   
width: 80%;
margin: auto;
margin-bottom: 2em;
}
#ellipse{
 background-repeat: no-repeat;
 background-size: contain;
 width: 300px;
 height: 300px;
position: absolute;
bottom: 10em;
left: calc(50%);
transform: translateX(-50%);


}
.failedImg{
    bottom: 15em !important;   
}
#uploadBtn svg{
margin: 0 5px;
}
#cancel{
    width: 40%;
    background: rgba(0, 0, 0, 0.83); 
}
.successModal #cancel{
    width: 50%;   
}
.successModal{
padding-top: 10em;
padding-bottom: 60px;
transform: translate(-50%, -30%);

}
#confirm{
    width: 40%;
    background: #DE1616;
}
#btnSpace{
    width: 20%;
    display: inline-block;
}
.navbarEn #search{
    margin-left: 0;
    margin-right: 0.5em;
}



#logoutBtn{
    margin-left: 4em;
    margin-right: 0.5em;
    border-radius: 6px;
    background: #E9E5D3 !important;
    padding: 5px 6px;
}
.navbarEn #logoutBtn{
    margin-left: 0.5em !important;
    margin-right: 4em !important;
}
.navbarEn #srchInput svg{
    left: 10px;
    right: auto !important;
}

.navbarEn #srchInput input{
    padding-left: 35px !important;
    padding-right: 0 !important;

}
.navbarEn #flexName p{
    margin-right: 0;
    margin-left: 0.5em;
}
.navbarEn #usrImg{
    
    margin-left: 0}

   .navbarEn .userNav button{
left: auto;
right: 0;
transform: scaleX(-1);

    }

   .navbarEn #navList svg{
    margin-left: 0;

    margin-right: 5px;
   }

    .navbarEn .closeNav{
right: auto !important;
left: 0.5em;
    }
  .navbarEn  .mobileLinksClose{
    transform: translateX(300px);
  }
  .navbarEn .mobileLinks{
    left: auto !important;
    right: 0;
  }
.userName p{
    font-size: 16px !important;
}
.srchContainer{
    padding: 0 60px;
}
.srchContainer{
    margin-bottom: 48px;

}
.modalEn #ellipse{   bottom: 13em !important;}


@media screen and (max-width: 767px){
   
    .container{
    max-width: unset !important;
    padding: 0 5vw !important;
    }
    #srchInput input{
        min-width: 230px;
    }
   
    #srchBtn{
       margin: 0 1.5em; 
    }
}
@media screen and (max-height: 764px){
    .loginLogo{
        height: 20vh;
        margin-bottom: 0;
    }
    #languageBtn button{
        padding: 7px;
    }
}
@media screen and (max-width: 1374px){
    .srchContainer{
        padding: 0 23px;
    }
}

@media screen and (max-width: 1458px){
    .buttonsDiv{
position: absolute;
width: max-content;
left: 0;
    }
    .dashboardEn .buttonsDiv{
        right: 0;
        left: auto !important;
    }
    #actionDiv{
        position: relative;
    }
    #orderDate{
        margin-top: 68px;
    }
    #orderDiv #orderDate{
        margin-top: 98px;
 
    }
}
@media screen and (max-width: 1024px){
    .removeMobile{
        display: none;
    }
    .srchCol{
        width: 100% !important;
    }
}
@media screen and (max-width: 1139px){
 
    #hello{
        font-size: 24px;
        margin-bottom: 5px

    }
    #srchBtn{
        margin-right: 32px;
        padding: 9px  32px;    }
  
    .dashboardEn #srchBtn{
        margin-right: 0;
        margin-left: 19px;
    }
     #statusBtn{
margin-left: 12px !important;
    }
    .dashboardEn #statusBtn{
        margin-right: 12px !important;
        margin-left: 0 !important;

            }
}



@media screen and (max-width: 690px){
    #dataDiv{
        max-width: 190px;
    }
}
@media screen and (max-width: 620px){
    #navbarMobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }
    #navbarMobile button{
        background-color: transparent;
        border: none;
    }
    #navbar{
        display: none;
    }
}
@media screen and (max-width: 585px){
    .buttonsDiv{
      position: relative;
        width: auto;
    }
    #orderDate{
        margin-top: 0 !important;
    }
    #srchresult{
        border: none;
    }
    #resultTitle{
        padding-bottom: 15px;
    }
    #resultDiv{
        flex-direction: column-reverse;
    }
   
    #actionDiv button{
        width: 47%;
    }
    .locLink button{
        width: 100% !important;

    }
    .locLink{
        width: 47%;

    }
    #actionDiv div{
        display: flex;
        justify-content: space-between;
    }
    #startBtn{
        margin: 0;
      
    }
    #resnum{
        margin-top: 1em;
    }
    #orderDate{
        display: none;
    }
    .mobileDate{
        display: block !important;
        text-align: start !important;
        margin-bottom: 1em !important;
    }
    #hello{
        font-size: 24px;
        margin-bottom: 5px;
    }
    #srchInput{
        display: block;
    }
    #srchInput input{
        width: 100%;
        margin-bottom: 1em;
    }
    #srchBtn{
        margin: 0 !important;
        font-size: 14px;
    }
    #srchresult{
        padding: 0;
    }
    #spaceBtn{
        flex-direction: column-reverse;
    }


#modalTitle{
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 1em;
   
}
#modalText{
    font-size: 16px;
    color: #667085;
}
.imgModal{
    padding: 20px;
    padding-top: 40px;
}

#ellipse{
  
    width: 45vw;
    height: 45vw;
    min-height: 200px;
    min-width: 200px;
   bottom: 8em;
   left: calc(50%);
   transform: translateX(-50%);
   
   
   }
   .failedImg{
       bottom: 15em !important;
   }
   .successModal{
    padding-top: 7em;
    padding-bottom: 40px;
    transform: translate(-50%, -30%);
    
    }
    .modalEn #ellipse{   bottom: 9em !important;}


}
@media screen and (max-width: 381px){
    #ellipse{   bottom: 13em;}

   
}
.dashBoardBckg{
    min-height: 100vh;
    height: auto !important;
    background-position: center !important;
}
@media screen and (min-width: 1088px){
    .dashBoardBckg{
   
    }
}
button:disabled{
    background-color: #ca9d6d !important;
}