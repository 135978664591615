#statusBtn{
    font-weight: 700;
font-size: 16px;
line-height: 20px;

color: #039855;
background: #D1FADF;
border-radius: 16px;
border: none;
padding: 7px 15px;

    margin-left: 2em;
}
.ordersButtons button{
  margin: 0 !important;
}
.ordersButtons a{
  margin:0 0.7em;
}

.line{
    width: 2px;
    height: 40px;
    background: #ED9434;

  } 
  .statusDiv{
    text-align: start;
  }
  .statusDiv button{
    padding:8px 12px !important;
    margin: 0;
    font-size: 14px !important;
    margin-bottom: 0.5em;
    line-height: 16px !important;
  }
  .circleActive{
      background-color: #F9F5FF;
      border-radius: 100%;
      margin-bottom: 2px;
  }
  #timeline{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      display: inline-flex;

  }
  #timelineData{
      display: inline-block;
  }
  #timelineData{
margin: 2em 1em;
margin-bottom: 0;
  }
  #timelineData div {
    margin-top: 40px;
}
  .timeTtitle{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    color: #344054;
    margin-bottom: 0;
  }
  .time{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    margin-bottom: -6px;

    color: #475467;
  }
  #activeStep p{ 
    color: #ED9434;

  }
@media screen and (max-width: 585px){
    #statusBtn{
        font-size: 14px;

    }
   .modalEn #ellipse {
      bottom: 13em;

    }
}
@media screen and (max-width: 409px){
  .modalEn #ellipse {
    bottom: 14em;
  }
  } 
  @media screen and (max-width: 404px){
    .modalEn #ellipse {
      bottom: 16em;
    }
    } 

    @media screen and (max-width: 716px){
      .ordersButtons #statusBtn{
  display: block;
  margin: auto !important;
  margin-bottom: 0.5em !important;
      }
      } 
      @media screen and (max-width: 585px){
        .ordersButtons #statusBtn{
   margin-bottom: 0 !important;
        }
        } 