@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&family=Inter:wght@400;500;600;700&display=swap');

.App {
  text-align: center;
}
body{
  margin: 0;
direction: rtl;
}
p,input, label, input::placeholder,button,a,span{
  font-family: 'Cairo' !important;
  font-style: normal;

}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;

}

input[type="number"] {
  -moz-appearance: textfield;
}
input:focus{
    
  border: 2px solid #ED9434 !important;
  outline: none !important;
}